import { htmlResolver } from 'helpers/htmlResolver';
import cacheData from 'memory-cache';
import { ContentPage } from 'models';
import { normalize } from 'pages/api/sitemap';
import { useEffect, useState } from 'react';
import { Translations } from 'store/translations';

import { getLocalizedDate } from './getLocalizedDate';
import { imageFormatter } from './imageFormatter';
import { OverviewItemModel } from './types/overviewItemModel';

export const getBasicOverviewItem = (
  page: ContentPage,
  linkTextKey: string,
  ariaLinkTextKey: string,
  translations: Translations,
  locale?: string,
) => {
  const textHtml = htmlResolver(page.elements.summaryDescription);
  //  console.log('locale ',cacheData.get('language'))
  // useEffect(()=>{

  // },[locale])
  const overviewItem = {
    id: page.system.id,
    headline: page.elements.summaryTitle.value,
    text: textHtml,
    link: Object.keys(normalize(page, cacheData.get('language'), undefined, true, translations))[0],
    linkText: cacheData.get('translation')?.common(linkTextKey),
    ariaLinkText: translations.aria(ariaLinkTextKey),
    image: imageFormatter(page.elements.summaryImageFrontify.value),
  } as OverviewItemModel;
  return overviewItem;
};

export const getOverviewItemForOverviewBlock = (page: ContentPage, translations: Translations, locale?: string) => {
  return getBasicOverviewItem(page, 'viewMore', 'about', translations, locale);
};

export const getSectorOrServiceOverviewItem = (
  page: ContentPage,
  translations: Translations,
  type?: 'sector' | 'service',
) => {
  const overviewItem = getBasicOverviewItem(page, 'readMore', 'about', translations);
  if (
    type?.includes('service') &&
    (page.elements.tagsService.value[0]?.codename || page.elements.tagsService.value[0]?.name)
  ) {
    overviewItem['tag'] = translations.service(page.elements.tagsService.value[0]?.codename, null, {
      fallback: page.elements.tagsService.value[0]?.name,
    });
  }
  if (
    type?.includes('sector') &&
    (page.elements.tagsSector.value[0]?.codename || page.elements.tagsSector.value[0]?.name)
  ) {
    overviewItem['tag'] = translations.sector(page.elements.tagsSector.value[0]?.codename, null, {
      fallback: page.elements.tagsSector.value[0]?.name,
    });
  }
  return overviewItem;
};

export const getNewsOverviewItem = (page: ContentPage, translations: Translations, locale?: string) => {
  const overviewItem = getBasicOverviewItem(page, 'readMore', 'about', translations);
  overviewItem['metadata'] = getLocalizedDate(
    page.elements.summaryDate.value ?? page.system.lastModified,
    translations.locale,
  );

  return overviewItem;
};
